import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1647881976/fran-trejo-art_lrvpf0.png",
        "alt": "Francisco Trejo"
      }}></img></p>
    <blockquote>
      <p parentName="blockquote">{`Francisco es una de esas personas que te caen bien desde el momento en que lo conoces, el amigo dispuesto a prestarte unos pesos para tu autobús, regalarte un cigarro o platicar rápidamente en los pasillos de una escuela, en la barra de un bar o en una llamada por Zoom. La afabilidad que lo caracteriza a veces hace que, aquellos que hemos podido convivir con él, olvidemos que estamos ante la presencia de un laureado poeta.`}</p>
      <p parentName="blockquote">{`La cita para la entrevista, que en seguida se reproduce, fue más bien una cita con el destino, porque verá, querido lector, en los meros inicios de `}<em parentName="p">{`Colibrí`}</em>{`, cuando la idea era apenas una pequeña oruga, Francisco, amablemente, me compartió su experiencia como editor de medios culturales e iluminó un poco este camino que ya cumple un año.`}</p>
    </blockquote>
    <p><strong parentName="p">{`-Guadalupe Álvarez:`}</strong>{` Bueno, creo que es mejor que tú te presentes. Platícanos, ¿quién es Francisco Trejo? ¿A qué te dedicas? ¿Cuál es tu trayectoria?`}</p>
    <p><strong parentName="p">{`-Francisco Trejo:`}</strong>{` Mi nombre completo es Francisco Trejo Hernández, y siempre firmo mis libros como Francisco Trejo. Nací en la Ciudad de México en 1987. Estudié la licenciatura en Creación Literaria en la Universidad Autónoma de la Ciudad de México (UACM), la especialización en Literatura Mexicana del Siglo XX en la Universidad Autónoma Metropolitana (UAM), Unidad Azcapotzalco, y, en esta misma universidad, la maestría en Literatura Mexicana Contemporánea. He publicado más de diez libros de poesía, entre los que se encuentran algunos que tuvieron la fortuna de resultar ganadores en certámenes nacionales e internacionales. Por ejemplo, con mi libro `}<em parentName="p">{`El tábano canta en los hoteles`}</em>{` obtuve el VIII Premio Nacional de Poesía Ignacio Manuel Altamirano, 2012. Todavía era estudiante de licenciatura cuando gané este premio, y me siento muy orgulloso de haberlo obtenido en ese momento, porque fue una especie de graduación, ya que, como te comentaba, estudié una licenciatura en creación literaria, y la apuesta de la carrera es que los estudiantes se formen como escritores. Y bueno, que reconozcan el trabajo, en un certamen nacional, puede decir más que el título universitario.`}</p>
    <p><img parentName="p" {...{
        "src": "https://www.crearensalamanca.com/wp-content/uploads/2020/05/8-3-392x570.jpg",
        "alt": "Francisco Trejo"
      }}></img></p>
    <p>{`Posteriormente obtuve, en Colombia, el XIII Concurso Bonaventuriano de Poesía 2017. Se trata de un certamen internacional, muy atractivo por la cantidad de autoras y autores que postulan su obra, procedentes de diversas partes del mundo. Me sorprendió mucho ganarlo, y me alegró, al mismo tiempo, porque fue la primera vez que concursé fuera del país. Fue una motivación muy grande. Luego, con `}<em parentName="p">{`Penélope frente al reloj`}</em>{`, conseguí el VI Premio Internacional de Poesía Paralelo Cero 2019. La noticia me sacudió por completo, porque es un concurso de renombre, en el que había participado un par de veces, en ediciones pasadas, con libros que aparecieron en el listado de finalistas. Además, es un libro al que le tengo especial aprecio, porque se trata de un homenaje a Teresa, mi madre. Y esta meta siempre ha estado vigente en mi quehacer literario: partir de mi propio génesis.
Y bueno, recientemente, con mi obra titulada `}<em parentName="p">{`Esdrújulo monstruo`}</em>{`, animal de lágrimas en sus ojos amarillos, que será publicada en el transcurso de este año, obtuve el XIV Premio de Poesía Editorial Praxis 2021. También se trata de un concurso internacional, muy estimulante, porque lo organiza una editorial especializada en poesía, con más de 40 años de experiencia. Praxis es, además, pionera de las editoriales independientes en México.`}</p>
    <p><strong parentName="p">{`-GA: Para ampliar un poco lo que has mencionado, ¿por qué escribes? ¿En qué momento, el joven Francisco, decidió que quería ser escritor, poeta, particularmente?`}</strong></p>
    <p><strong parentName="p">{`-FT:`}</strong>{` Me recuerdo en la primaria y en la secundaria, escribiendo ideas al final de mis cuadernos de clase; textos breves que, pensaba, eran poemas. Posteriormente, cuando ingresé al bachillerato, empecé a convivir con gente de diversas búsquedas y variadas inquietudes, entre las que se encontraba la escritura; entonces me propuse la meta de la creación literaria, como un medio de socialización con aquellas compañeras y aquellos compañeros, a los que les interesaba la dramaturgia y la narrativa, principalmente. A partir de este momento, para dialogar o para divertirme, insistí en escribir algo más en forma, y no sólo ideas al final de un cuaderno que después terminaba en la basura. Incluso utilicé esta escritura incipiente para interactuar en mis clases, porque en el aula, muchas veces, se tomaba en cuenta la creatividad para las calificaciones. Como yo no era muy ingenioso para hacer cartulinas y ese tipo de cosas, cuando me tocaba exponer escribía cuentos, en los que explicaba el tema en cuestión. Este ejercicio gustaba, en general.
En ese tiempo no pensaba dedicarme a la escritura; sin embargo, algunas personas de la escuela me exhortaban a estudiar letras. Fue una amiga la que me buscó, años más tarde, para comentarme que la UACM cuenta con la licenciatura en Creación Literaria. Me interesó bastante, me informé e ingresé. Ya inmerso en los talleres de la licenciatura, decidí enfocarme más en la poesía, porque es el discurso con el que más me identifico. `}<strong parentName="p">{`A pesar de que leo narrativa, ensayo y otros géneros, me siento mejor escribiendo poesía. Creo que es el discurso que se asemeja más a lo que quiero decir, desde mi perenne insatisfacción.`}</strong>{` En otros términos, es un discurso que puedo adaptar al ritmo de la carne, frente al mundo que incomoda, la mayoría de las veces. De tal modo, la voz poética, sobre el ritmo, es un jinete en un caballo ciego, intuitivo. En la guerra de opuestos, opto más por las pasiones que por la razón. Quizá por esto decidí enfocarme en la poesía.`}</p>
    <p><strong parentName="p">{`-GA: ¿La poesía es algo que tú te ves escribiendo por siempre?`}</strong></p>
    <p><strong parentName="p">{`-FT:`}</strong>{` Pues he experimentado con otros discursos. Tengo un libro de ensayos literarios, muy pequeño, que fui reuniendo de algunas colaboraciones en revistas y algunos concursos en los que participé, obteniendo mención honorífica, como es el caso del Punto de Partida de la UNAM. También tengo un libro de entrevistas que les hice a cuatro poetas del exilio latinoamericano en México, que surgió a raíz de la maestría en la UAM. Y bueno, no descarto la posibilidad de, en determinado momento, escribir narrativa. Creo que ya estoy divagando un poco. Regresando a tu pregunta, la respuesta es sí, me veo escribiendo poesía mucho tiempo más, pero alternando con otros géneros.`}</p>
    <p><strong parentName="p">{`-GA: Y, de todos estos libros y escritos, ¿le tienes cariño especial a alguno?`}</strong></p>
    <p><strong parentName="p">{`-FT:`}</strong>{` Sí, tengo un libro que es mi favorito, porque es muy íntimo: Penélope frente al reloj. Tiene mucho valor para mí porque es el que más trabajo me ha costado escribir, por toda la carga emocional que representa. `}<strong parentName="p">{`Tiene bastante de mí y bastante de mi madre. Siempre lo proyecté así. Soñaba con escribirlo, ganar un premio y entregárselo a Teresa, con una bonita portada.`}</strong></p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1647891866/book2_xfzntt.jpg",
        "alt": "Francisco Trejo"
      }}></img></p>
    <p>{`Y así pasó. Ahora me siento bien con esta obra que se publicó en Ecuador, agotando su edición en menos de un año, y que pronto se reeditará en México, bajo el sello de la UACM, la universidad donde me formé como escritor. Este libro es el que más se acerca a la espina que he vivido. Recuerdo que no fue fácil llegar a los poemas que lo conforman. Mientras lo escribía, pensaba que se iba a desmoronar un poco la pirámide emocional que motivó el proyecto; pero, a veces, cuando tomo el libro y leo de manera fría, me sorprende de súbito el quiebre de voz. No es algo que tenga premeditado, se da así. Es un libro que me gusta porque me conduce al conocimiento de mi propia persona, mientras me inquieta y me sorprende. Todavía me asedien algunos escorpiones cuando lo abro.`}</p>
    <p><strong parentName="p">{`-GA: ¿Hay algún libro que te hayas arrepentido de publicar?`}</strong></p>
    <p><strong parentName="p">{`-FT:`}</strong>{` Creo que nunca me he arrepentido de lo que he publicado; más bien, he sido muy cuidadoso, porque tengo libros que nunca voy a publicar. Aunque les tengo cierto aprecio, porque tengo la impresión de que, en cada proyecto que desarrollo, mejora mi lenguaje. Estos escritos, malos o no, están ahí, archivados, como un escalón que me ha permitido llegar a otras propuestas que me gustan más.`}</p>
    <p><strong parentName="p">{`-GA: ¿Piensas que cada uno de tus libros ha sido una evolución de ti como poeta?`}</strong></p>
    <p><strong parentName="p">{`-FT:`}</strong>{` Sí, siento que la escritura de cada uno de mis libros me ha hecho mejorar como escritor. Cada libro es un problema, en sí mismo, que me propongo resolver de la mejor manera. El problema radica en la meta de enunciar algo que ya todos saben. Es decir, crear un atajo, hallarlo, para llegar al mismo sitio al que se llega, sin necesidad de la poesía: la verdad. Si se logra esto, entonces el discurso poético adquiere un valor sustancial en el lector que abandona el viejo camino sin poesía y opta por cruzar la nueva brecha, porque quizá lo hace experimentar la vida, sus cosas, desde un paraje más emocionante. La solución del problema es, entonces, proponer una verdad poética.`}</p>
    <p><strong parentName="p">{`-GA: A propósito de esta evolución, de esta meta que buscas, ¿te encuentras escribiendo algo nuevo?`}</strong></p>
    <p><strong parentName="p">{`-FT:`}</strong>{` Ahora es un momento complicado para mi producción literaria, por la incertidumbre que hemos experimentado desde el comienzo de la pandemia. Jamás había dejado de escribir tanto tiempo, como ahora. Por lo tanto, me he dedicado a la cuestión editorial, que es otra parte de la poesía que me gusta. En cuanto a escritura, voy poco a poco, aterrizando un nuevo proyecto. Creo que la idea la tengo muy bien planteada, pues me gustan los pocos textos que he escrito hasta ahora. Mientras lo desarrollo, me seguiré dedicando a la edición de poesía, que es otra parte importante de mi comprensión de esta disciplina, porque me permite observar cuál es el estado de la poesía, qué es lo que está pasando en otras latitudes, cuáles son las preocupaciones actuales y, con base en esto, también evaluar lo propio. Así me veo, en el futuro, trabajando poesía, compartiéndola, creando espacios.
En plena pandemia, mi amiga Marisa Russo y yo creamos una revista que se llama `}<a parentName="p" {...{
        "href": "https://nuevayorkpoetryreview.com/"
      }}>{`Nueva York Poetry Review`}</a>{`. Y bueno, creo que ha funcionado bastante bien. La gente la busca, quiere que su poesía se publique ahí. Y eso me llena de orgullo. Creo que se pueden hacer más cosas todavía, para hacer llegar lo que somos en el mundo, desde el discurso literario.`}</p>
    <p><strong parentName="p">{`-GA: Me surge ahora la pregunta: ¿tú cómo ves la poesía actual? De lo que te ha tocado revisar y publicar, ¿cuál sería tu punto de vista como creador de poesía?`}</strong></p>
    <p><strong parentName="p">{`-FT:`}</strong>{` La diversidad de propuestas es muy emotiva. Cada vez más hay un intento por sacar el discurso poético de los espacios habituales. Quiero decir que la hibridez de los géneros toca terrenos interesantes. Asimismo, los espacios en los que compartimos poesía se han ampliado. Jamás, como ahora, había advertido que tanta gente busque publicar lo que escribe. Y no siempre se necesita ser un escritor reconocido para conseguirlo, hay muchas alternativas. Después de todo, el acto de escribir, de forma profesional o no, es una forma digna de entender nuestro paso por el mundo, y de elevar nuestra sustancia real como especie: el lenguaje, el signo, el símbolo. `}<strong parentName="p">{`Se valora más, desde luego, si esta escritura está ejecutada de la mejor forma posible; es decir, si la naturaleza del mensaje tiene los elementos necesarios para quedarse en quien lo busca. Esto implica un compromiso con la disciplina y con el lector.`}</strong>{`
En la actualidad existen muchos soportes destinados a la poesía; si a una persona le cierran la puerta en una revista, busca otro lugar. A pesar de esto, me parecen importantes los criterios que cada espacio formula para aceptar lo que publica. Toda revista debe tomar una postura ante el presente y sus fenómenos más preocupantes, tengan que ver, o no, con el universo artístico.`}</p>
    <p>{`Al finalizar esta entrevista que me ha permitido saber cómo alguien se hace escritor, agradezco a Francisco la disposición y la apertura. Nos ponemos al día, mandamos saludos mutuos a los amigos compartidos y nos despedimos con el deseo de vernos pronto de forma física.`}</p>
    <h3><strong parentName="h3">{`Palabras de felicitación para `}<em parentName="strong">{`Colibrí`}</em>{`:`}</strong>{` He seguido de cerca lo que están haciendo en `}<em parentName="h3">{`Colibrí`}</em>{`, y me parece fascinante; por eso me alegra que me hayas invitado esta entrevista. ¡Qué bueno! Deseo que la charla tenga algún aporte valioso para sus lectores. Y nada, quiero felicitarlas y también invitar a la gente a que se acerque a sus contenidos. Me da gusto que el proyecto editorial se haya concretado, finalmente, y que ya esté cumpliendo un año. Lo digo sinceramente, porque, así como tú, otras personas se han acercado a mí para preguntarme cómo iniciar su propia revista literaria. Aunque he notado interés, no todas estas personas han tenido la voluntad necesaria para abrir un espacio serio, como éste.`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      